import { useCallback, useEffect, useRef } from 'react';

function useScrollTo(id, options, optionalElem) {
  let config = {
    behavior: 'smooth',
    initialScrollDelay: 750,
    offset: 0,
    shouldScrollIntoView: false
  };

  if (options && typeof options === 'object') {
    config = {
      ...config,
      ...options
    };
  }
  const configRef = useRef(config);
  const hash = window.location.hash;
  const path = window.location.pathname;
  const scroll = useCallback(
    scrollConfig => {
      try {
        const config = {
          ...configRef.current,
          ...(scrollConfig || {})
        };
        const { behavior, block, offset, shouldScrollIntoView } = config;
        const elem =
          config.elem || (id && document.querySelector(id)) || optionalElem;
        const top = elem
          ? elem.getBoundingClientRect().top + (window.scrollY - offset)
          : null;
        const canScrollIntoView = shouldScrollIntoView && elem;

        if (canScrollIntoView) {
          elem.scrollIntoView({ behavior, block });
        } else if (top) {
          window.scrollTo({
            behavior,
            left: 0,
            top
          });
        }
      } catch (err) {
        if (process.env.__SITE_ENV__ === 'local') {
          console.error(err);
        }
      }
    },
    [id, optionalElem]
  );

  useEffect(() => {
    const config = configRef.current;
    if (id && hash === id) {
      setTimeout(() => {
        scroll();
      }, config.initialScrollDelay);
    }
  }, [
    hash,
    id,
    path /* handle scenarios like store detail to store detail navigation where the hash stays the same but the path changes. */,
    scroll
  ]);

  const delayedScroll = useCallback(
    config => {
      setTimeout(() => {
        scroll(config);
      }, 50); // allow other scrolls to be triggered first
    },
    [scroll]
  );

  return delayedScroll;
}

export default useScrollTo;
