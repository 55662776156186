import React from 'react';
import { Helmet } from 'react-helmet';

import BreadcrumbsComponent from '../../library/page/components/breadcrumbs/breadcrumbs';

import { isArrayWithLength } from '../../shared/utils/validators/validators';

interface BreadcrumbsProps {
  className?: string;
  crumbs: {
    name: string;
    url?: string;
  }[];
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const { crumbs } = props;

  const breadcrumbsLD = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': isArrayWithLength(crumbs)
      ? crumbs.map((crumb, index) => ({
          '@type': 'ListItem',
          'item': crumb.url
            ? `${window.location.origin}${crumb.url}`
            : window.location.href,
          'name': crumb.name,
          'position': index + 1
        }))
      : []
  };

  return isArrayWithLength(crumbs) ? (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsLD)}
        </script>
      </Helmet>
      <BreadcrumbsComponent {...props} />
    </>
  ) : null;
}

export default Breadcrumbs;
