import React from 'react';
import { Helmet } from 'react-helmet';
import parse, { domToReact } from 'html-react-parser';
import queryString from 'query-string';

import Button from '../../../library/button/button';
import { trackAction } from '../analytics/analytics';

import { PRODUCT_TYPES } from '../../constants/products';

// https://gist.github.com/goldhand/70de06a3bdbdb51565878ad1ee37e92b
export const parseStyles = style =>
  style
    .split(';')
    .filter(style => style.split(':')[0] && style.split(':')[1])
    .map(style => [
      style
        .split(':')[0]
        .trim()
        .replace(/-./g, c => c.substr(1).toUpperCase()),
      style.split(':')[1].trim()
    ])
    .reduce(
      (styleObj, style) => ({
        ...styleObj,
        [style[0]]: style[1]
      }),
      {}
    );

const defaultParserOptions = {
  replace: ({ name, attribs, children }) => {
    if (name !== 'button' && !attribs) return;

    if (name === 'button' || name === 'a') {
      if (name === 'a' && /mailto:|tel:/.test(attribs.href)) {
        return;
      }

      let buttonProps = {
        className: attribs.class,
        style: attribs.style
          ? { ...parseStyles(attribs.style), display: 'block' }
          : null
      };

      if (
        attribs.class &&
        (attribs.class.includes('button--primary') ||
          (attribs.class.includes('btn-default') &&
            !attribs.class.includes('inverse')))
      ) {
        buttonProps.isPrimary = true;
      } else if (
        attribs.class &&
        (attribs.class.includes('dt-button--ghost') ||
          attribs.class.includes('inverse'))
      ) {
        buttonProps.isSecondary = true;
      }

      if (attribs.href) {
        buttonProps.href = attribs.href;
      }

      if (attribs.target) {
        buttonProps.target = attribs.target;
      }

      if (attribs['data-trackpayload']) {
        try {
          const payload = JSON.parse(attribs['data-trackpayload']);
          buttonProps.onClick = () => trackAction('content_event', payload);
        } catch (e) {
          console.error(
            'Unable to apply data-trackpayload attribute in defaultParserOptions',
            `The value supplied was: ${attribs['data-trackpayload']}`,
            e
          );
        }
      }

      return (
        <Button {...buttonProps}>
          {domToReact(children, defaultParserOptions)}
        </Button>
      );
    }
  }
};

function replaceScripts(domNode) {
  // TODO: Investigate other AEM options that work for CSR apps and remove
  // This is necessary allow AEM/CMS scripts to load that assume SSR pages
  // with synchronous script loading
  const { children, attribs } = domNode;
  const scriptInnerHTML = children?.[0]?.data;

  const newAttribs = { ...attribs };
  if (newAttribs.async !== 'true' && attribs.async !== '') {
    delete newAttribs.async;
  }

  return (
    <Helmet>
      <script {...(newAttribs || {})}>
        {scriptInnerHTML &&
          (scriptInnerHTML?.trim().charAt(0) === '{'
            ? scriptInnerHTML
            : `
              (function() {
                var attempts = 0;
                var run = function() {
                  attempts++;
                  try {
                    ${scriptInnerHTML}
                  } catch(err) {
                    console.error('CMS script failed | ', err);
                    if (attempts <= 5) {
                      console.warn('Rerunning CMS script - attempt#', attempts);
                      setTimeout(run, 500);
                    }
                  }
                }

                setTimeout(run, 200);
              })();
              `)}
      </script>
    </Helmet>
  );
}

export function cmsContentParser(content = '', parserOptions) {
  const composedParserOptions = {
    replace: domNode => {
      if (domNode.name === 'script') {
        return replaceScripts(domNode);
      }

      if (parserOptions && parserOptions.replace) {
        const replacements = parserOptions.replace(domNode);

        if (replacements) return replacements;
      }

      return defaultParserOptions.replace(domNode);
    }
  };

  return parse(content, composedParserOptions);
}

export function getBrandCategoryParams() {
  const { pathname } = window.location;
  const { isLivePreview } = queryString.parse(window.location.search);

  const pathParams = pathname.split('/').filter(val => val);
  let name, productType, seoName;

  if (pathParams.length > 1) {
    name = pathParams[pathParams.length - 1];
    productType = PRODUCT_TYPES[pathParams[0].toUpperCase()];
    seoName = `${name}-${pathParams[0]}`;
  }

  return {
    isLivePreview: isLivePreview === 'true',
    name,
    productType,
    seoName
  };
}

const getDataAttributes = domNode => {
  const dataAttributes = {};

  if (typeof domNode.attribs === 'object') {
    Object.keys(domNode.attribs).forEach(key => {
      if (key.startsWith('data')) {
        const formattedKey = key
          .substring(5)
          .replace(/-./g, word => word[1].toUpperCase());

        dataAttributes[formattedKey] = domNode.attribs[key];
      }
    });
  }

  return dataAttributes;
};

export function parseCmsContentAndMapComponents(
  htmlContent,
  componentMap,
  replace
) {
  return cmsContentParser(htmlContent, {
    replace: domNode => {
      const { themeModule, ...props } = getDataAttributes(domNode);

      if (themeModule) {
        if (componentMap.has(themeModule)) {
          const DynamicComponent = componentMap.get(themeModule);

          return <DynamicComponent {...props} />;
        }
        return;
      }

      return replace?.(domNode);
    }
  });
}

export function getCmsComponentsInSlots(contentSlots, callback) {
  if (!Array.isArray(contentSlots)) return [];

  const cmsComponentsInSlots = [];

  for (let s = 0; s < contentSlots.length; s++) {
    const { cmsComponents } = contentSlots[s] || {};

    if (Array.isArray(cmsComponents)) {
      for (let c = 0; c < cmsComponents.length; c++) {
        const cmsComponent = cmsComponents[c];

        cmsComponentsInSlots.push(cmsComponent);

        if (typeof callback === 'function' && cmsComponent) {
          callback(cmsComponent);
        }
      }
    }
  }

  return cmsComponentsInSlots;
}

export function getTextOverrides(cmsComponents, textIdMap) {
  const textOverrides = {};

  for (let key in textIdMap) {
    textOverrides[key] = cmsComponents?.find(
      c => c.name === textIdMap[key]
    )?.html;
  }

  return textOverrides;
}

/*
 * replaceMap should be a map of keys to replacement values:
 * interpolateCmsString({ 'siteName': 'Discount Tire' }, 'I like {{siteName}}') === 'I like Discount Tire'
 */
export function interpolateCmsString(replaceMap, cmsString) {
  if (
    typeof cmsString !== 'string' ||
    typeof replaceMap !== 'object' ||
    replaceMap === null
  ) {
    return cmsString;
  }

  return Object.keys(replaceMap).reduce(
    (acc, key) =>
      acc.replace(new RegExp(`{{${key}}}`, 'g'), replaceMap[key] || ''),
    cmsString
  );
}

export function interpolateCmsData(replaceMap, cmsData) {
  if (typeof cmsData === 'string') {
    return interpolateCmsString(replaceMap, cmsData);
  }

  if (Array.isArray(cmsData)) {
    return cmsData.map(cmsDatum => interpolateCmsData(replaceMap, cmsDatum));
  }

  if (typeof cmsData === 'object' && cmsData !== null) {
    const newCmsData = { ...cmsData };

    for (let key in cmsData) {
      newCmsData[key] = interpolateCmsData(replaceMap, cmsData[key]);
    }

    return newCmsData;
  }

  return cmsData;
}
