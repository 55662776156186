import React, { PropsWithChildren } from 'react';

import './content.scss';

interface ContentProps {
  className?: string;
  id?: string;
  isFullWidth?: boolean;
  showFrame?: boolean;
}

function Content(props: PropsWithChildren<ContentProps>) {
  const {
    children,
    className,
    id,
    isFullWidth = false,
    showFrame = false
  } = props;

  let styleName = isFullWidth ? 'full-width ' : '';
  styleName += showFrame ? 'content-frame' : 'content';

  return (
    <div
      className={className}
      data-testid="page-content"
      id={id}
      styleName={styleName}
    >
      {children}
    </div>
  );
}

export default Content;
