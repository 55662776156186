import React, { lazy, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader/root';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { last } from 'lodash';

import Page, { Content } from '../../library/page/page';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner';
import DefaultLayout from '../../layouts/default-layout/default-layout';

import { GET_CMS_PAGE } from './graphql/cms-page-queries';

import {
  useDataLayer,
  useMyAccount,
  useScrollTo
} from '../../shared/hooks/hooks';

import { trackAction } from '../../shared/utils/analytics/analytics';
import { parseCmsContentAndMapComponents } from '../../shared/utils/cms/cms';
import { getCmsPageId, metaTags } from '../../shared/utils/page/page';
import { isNill } from '../../shared/utils/validators/validators';

import {
  PAGE_NAME,
  PAGE_SUBCATEGORY,
  PAGE_TYPE
} from '../../shared/constants/data-layer';

import './cms-page.scss';

const BrandsByCategoryList = lazy(() =>
  import(
    /* webpackChunkName: "brands-by-category-list" */
    '../../components/brands-by-category-list/brands-by-category-list'
  )
);

const FleetFreeServicesCalculator = lazy(() =>
  import(
    /* webpackChunkName: "fleet-free-services-calculator" */
    '../../components/fleet-free-services-calculator/fleet-free-services-calculator'
  )
);

const ProductsByBrandList = lazy(() =>
  import(
    /* webpackChunkName: "products-by-brand-list" */
    '../../components/products-by-brand-list/products-by-brand-list'
  )
);

const ScheduleAppointmentButton = lazy(() =>
  import(
    /* webpackChunkName: "schedule-appointment-button" */
    '../../components/schedule-appointment-button/schedule-appointment-button'
  )
);

const TirePressureCostCalculator = lazy(() =>
  import(
    /* webpackChunkName: "tire-pressure-cost-calculator" */
    '../../components/tire-pressure-cost-calculator/tire-pressure-cost-calculator'
  )
);

const TireSizeCalculator = lazy(() =>
  import(
    /* webpackChunkName: "tire-size-calculator" */
    '../../components/tire-size-calculator/tire-size-calculator'
  )
);

const WiperCategoryTiles = lazy(() =>
  import(
    /* webpackChunkName: "wiper-category-tiles" */
    '../../components/wiper-category-tiles/wiper-category-tiles'
  )
);

const StoreServiceList = lazy(() =>
  import(
    /* webpackChunkName: "store-service-list" */
    '../../components/store-service-list/store-service-list'
  )
);

const CMSAuthModal = lazy(() =>
  import(
    /* webpackChunkName: "cms-auth-modal" */
    './components/cms-auth-modal/cms-auth-modal'
  )
);

export const componentMap = new Map([
  ['brands-by-category-list', BrandsByCategoryList],
  ['calculators/tire-size', TireSizeCalculator],
  ['calculators/low-tire-pressure-cost', TirePressureCostCalculator],
  ['calculators/fleet-free-services', FleetFreeServicesCalculator],
  ['schedule-appointment-button', ScheduleAppointmentButton],
  ['products-by-brand-list', ProductsByBrandList],
  ['store-service-list', StoreServiceList],
  ['wiper-category-tiles', WiperCategoryTiles],
  ['cms-auth-modal', CMSAuthModal]
]);

const pagesToAddEventTrackingRegExp = /\/financing|\/fleet|\/promotions/;
const pagesToHideMyAccountHeaderLinkRegExp = /\/fleet/;

function CMSPage() {
  const cmsPageId = getCmsPageId(window.location.pathname);
  const hideMyAccountHeaderLink = useMemo(
    () => pagesToHideMyAccountHeaderLinkRegExp.test(cmsPageId),
    [cmsPageId]
  );
  const { data, loading, error } = useQuery(GET_CMS_PAGE, {
    variables: {
      id: cmsPageId
    }
  });

  const { updateDataLayer } = useDataLayer();
  const { isLoggedIn } = useMyAccount();

  const { cms } = data || {};
  const { page: cmsPage } = cms || {};
  const {
    documentTitle = '',
    htmlContent = '',
    metaTags: metaData,
    breadcrumbs = []
  } = cmsPage || {};

  const content = parseCmsContentAndMapComponents(htmlContent, componentMap);

  let pageBreadcrumbs = [{ name: 'home', url: '/' }];
  const cmsPageBreadcrumbs = cmsPage?.breadcrumbs;

  if (!window.isPreRendering) {
    pageBreadcrumbs = breadcrumbs;
  }

  const location = useLocation();
  const scrollTo = useScrollTo(location.hash, { offset: 120 });
  useEffect(() => {
    if (location.hash && location.hash !== '#' && content.length > 0) {
      scrollTo();
    }
  }, [content, location.hash, scrollTo]);

  useEffect(() => {
    if (!isNill(cmsPageBreadcrumbs)) {
      // Add a delay to insure link trackActions fire before trackView's
      setTimeout(() => {
        updateDataLayer({
          [PAGE_NAME]: breadcrumbs.reduce(
            (acc, b, i) =>
              acc + `${b.name}${breadcrumbs.length - 1 !== i ? ' : ' : ''}`,
            ''
          ),
          [PAGE_SUBCATEGORY]:
            breadcrumbs.length > 0 ? `${last(breadcrumbs).name}` : '',
          [PAGE_TYPE]: 'content'
        });
      }, 100);
    }
  }, [breadcrumbs, cmsPageBreadcrumbs, updateDataLayer]);

  useEffect(() => {
    let pageEventTracking;

    if (pagesToAddEventTrackingRegExp.test(cmsPageId)) {
      (async function () {
        const module = await import('./utils/cms-page-event-tracking');
        pageEventTracking = module.default;

        window.addEventListener('click', pageEventTracking);
      })();
    }
    if (documentTitle.includes('404')) {
      trackAction('error_path_url', { url: window.location.href });
    }
    return () => {
      if (pageEventTracking) {
        window.removeEventListener('click', pageEventTracking);
      }
    };
  }, [cmsPageId, documentTitle]);

  useEffect(() => {
    function getPitpassStoreCode() {
      let storeCode;
      if (process.env.__SITE_ENV__ === 'stg') {
        storeCode = '1282';
      } else if (process.env.__SITE_ENV__ === 'qa') {
        storeCode = '1127';
      } else if (process.env.__SITE_ENV__ === 'dev') {
        storeCode = '1284';
      } else {
        storeCode = '2274';
      }
      return storeCode;
    }
    const storeCode = getPitpassStoreCode();

    const isPitpassLandingPage = location.pathname === '/pitpass';
    const isFromDTAT =
      document.referrer.includes('discounttire') ||
      document.referrer.includes('americastire') ||
      location.state?.prevPage;

    if (
      isPitpassLandingPage &&
      !isFromDTAT &&
      !location.search.includes('storeCode')
    ) {
      window.location.replace(`/pitpass?storeCode=${storeCode}`);
    }
  }, [htmlContent, location]);

  if (error && !window.isPreRendering) {
    throw error;
  }

  return (
    <DefaultLayout hideMyAccountHeaderLink={hideMyAccountHeaderLink}>
      {!window.isPreRendering && (
        <Helmet>
          <title>{documentTitle}</title>
          {metaTags(metaData || [])}
          {documentTitle === '404' && (
            <meta content="404" name="prerender-status-code" />
          )}
        </Helmet>
      )}
      <Page>
        {!window.isPreRendering && pageBreadcrumbs && (
          <Breadcrumbs crumbs={pageBreadcrumbs} />
        )}
        {!window.isPreRendering && (
          <>
            {loading ? (
              <LoadingSpinner styleName="loading" />
            ) : (
              <Content styleName={`${isLoggedIn ? 'logged-in' : 'logged-out'}`}>
                {content}
              </Content>
            )}
          </>
        )}
      </Page>
    </DefaultLayout>
  );
}

export default hot(CMSPage);
